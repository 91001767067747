import '@fortawesome/fontawesome-free/scss/fontawesome.scss'
import '@fortawesome/fontawesome-free/scss/brands.scss'
import '@fortawesome/fontawesome-free/scss/regular.scss'
import '@fortawesome/fontawesome-free/scss/solid.scss'
import 'typeface-poppins';
import './style.scss';

const mobileMenuToggle = document.getElementById('mobile-menu-toggle');
const mobileMenuToggleIcon = mobileMenuToggle.querySelector('i');
const primaryMenu = document.getElementById('primary-menu');

mobileMenuToggle.onclick = function() {
    if (mobileMenuToggleIcon.classList.contains('fa-bars')) {
        mobileMenuToggleIcon.classList.remove('fa-bars')
        mobileMenuToggleIcon.classList.add('fa-xmark')
        primaryMenu.classList.remove('nav__menu--hidden');
    } else {
        mobileMenuToggleIcon.classList.add('fa-bars')
        mobileMenuToggleIcon.classList.remove('fa-xmark')
        console.log('closing')
        primaryMenu.classList.add('nav__menu--hidden');
    }
}
